.App {
  position:absolute;
  width:100%;
  height:100vh;
  overflow:hidden;
  font-smooth: auto;
}

.guide {
  position:fixed;
  top:10px;
  left:10px;
  bottom:10px;
  display:flex;
  flex-flow:column;
  align-items: center;
  user-select: none;
}

.guide {
  position:fixed;
  top:20px;
  bottom:0px;
  left:0px;
  width:80px;
}

.line {
  height:70%;
  width:2px;
  border-radius:100%;
  opacity:0.1;
  background-color: #272727;
  transition: all 0.3s;
}
.second-line {
  height:0px;
  transition: all 0.3s;
  background-color: #181818;
  width:2px;
  border-radius:100%;
  top:80px;
  opacity:0.6;
}

.guide.projects-active .line {
  height:0px;
}

.guide.projects-active .second-line {
  height:70%;
}

.guide .label {
  position: relative;
  font-family: 'DM Mono', monospace;
  font-size:12px;
  opacity:0.5;
  transform: rotate(-90deg) translate(-100%);
  transition: all 0.1s;
}

.guide.projects-active .label {
  opacity: 0.8;
}

.splash {
  position:absolute;
  top:45%;
  right:0px;
  transform: translate(0,-50%);
  user-select: none;
  color: #272727;
}

.splash-text {
  font-family: 'Archivo', sans-serif;
  font-weight: 100;
  font-size: 120px;
  margin-bottom:10px;
}

.description {
  position: relative;
}

.subtext {
  font-family: "DM Mono", monospace;
  margin-left:30px;
  font-size:18px;
}

.subtext-decor {
  position:absolute;
  top: 50%;
  left: 270px;
  width:50px;
  z-index:-3;
  transform: translate(0,-50%);
  opacity:0.6;
  transition:all 0.1s;
}

.accent {
  z-index:4;
}

@media (max-width: 1200px) {
  .splash {
    position:absolute;
    top:45%;
    right:0px;
    transform: translate(0,-50%);
    user-select: none;
    color: #272727;
    text-align: right;
  }
  
  .splash-text {
    display:flex;
    flex-flow:column;
    font-family: 'Archivo', sans-serif;
    font-weight: 100;
    font-size: max(8vw, 50px);
    margin-bottom:10px;
  }

  .subtext-decor {
    position:absolute;
    left:initial;
    right: 10px;
  }
  .guide {
    width: 20px;
  }
}