.carousel {
  position: absolute;
  top: 80%;
  left: 450px;
  padding-bottom: 40px;
  transition: all 0.4s;
  & .highlight {
    background-color: #74efad;
    border: solid 4px #272727;
    box-shadow: 5px 5px 0px #272727;
    display: flex;
    flex-flow: column;
    max-width: 450px;
    max-height: 600px;
    & .label {
      display: flex;
      padding: 20px;
      & h3 {
        margin: 0px;
        font-family: "Archivo", sans-serif;
        font-weight: 800;
        font-size: 16px;
      }
      & .arrows {
        margin-left: 20px;
      }
    }
  }
}

.carousel .projects {
  display: flex;
  flex-flow: row;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 90vw;
  & .project {
    min-width: 410px;
    max-width: 410px;
    height: 500px;
  }
  & .project:last-child {
    margin-right: calc(300px * 4);
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.arrows {
  width: 10px;
}

@media (max-width: 1500px) {
  .carousel {
    width: 90%;
    left: 10%;
    & .project {
        min-width:300px !important;
        max-width:300px !important;
    }
    & .highlight {
        max-width: 300px;
        max-height: 600px;
    }
  }
}
