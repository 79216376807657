.project {
    position:relative;
    border-radius:10px;
    border: solid 4px #272727;
    margin: 20px 40px;
    background: rgb(255,255,255);
    transition: all 0.3s;
    display:flex;
    flex-flow:column;
    & .info {
        display:flex;
        flex-flow:column;
        padding:20px;
        padding-top:20px;
    }
    & h3.title {
        margin:0px;
        font-family: "Archivo", sans-serif;
        font-size:15px;
    }
    & img.title {
        height:40px;
        margin: 10px 0px;
        align-self:flex-start;
    }
    & .time {
        font-family: "DM Mono", monospace;
        font-size:12px;
    }
    &:hover {
        cursor: pointer;
        box-shadow:0px 4px 10px rgba(0,0,0,0.4);
        border:solid 4px #181818;

    }
    &:hover:before {
        opacity:0.5;
    }
    & .preview {
        align-self: center;
        display:flex;
        flex-flow:column;
        align-items: center;
        justify-content: center;
        width:90%;
        overflow:hidden;
        margin-top:auto;
        border-radius:10px;
        box-shadow:0px 5px 10px rgba(0,0,0,0.2);
        & .web-preview {
            max-height:300px;
        }
    }
    & .description {
        margin-top:auto;
        bottom:0px;
        z-index:99;
        padding: 20px;
        font-family: "DM Mono", monospace;
        font-size:14px;
    }
    & .tools {
        z-index:99;
        position:absolute;
        right:20px;
        user-select: none;
        top:20px;
        background-color:#181818;
        border-radius:5px;
        & .dot {
            margin-left:auto;
            width:40px;
            height:40px;
            display:flex;
            align-items: center;
            justify-content: center;
            border-radius:5px;
            color:white;
            font-family: 'Archivo', sans-serif;
            border: solid 2px transparent;
            transition:all 0.1s;
            & span {
                font-size:24px;
            }
        }
        & .dot:hover {
            box-shadow:0px 5px 10px rgba(0,0,0,0.1);
            border: solid 2px #181818;
            color:#181818;
            background-color: #dbdbdb;
        }
        & ul.list {
            padding:10px;
            list-style: none;
            margin:0px;
            & li {
                margin:0px;
                color:#dbdbdb;
                font-family: "DM Mono", monospace;
                font-size:14px;
                margin: 5px 10px;
            }
        }
    }
}